import { Link } from 'gatsby'
import React from 'react'
import pwr4logo from '../assets/images/pwr4logo.png'
import fb_logo from '../assets/images/Facebook_logo.png'
import ig_logo from '../assets/images/IG_logo.png'
import li_logo from '../assets/images/LinkedIn_logo.png'
import twit_logo from '../assets/images/Twitter_logo.png'

// const onEmailSubmit = (e) => {

// }

const Footer = props => (
  <footer id="footer">
    <section>
      <img src={pwr4logo} id="footer-logo"/>
      {/* <h2 id="footer_header">Get in touch</h2>
      <p id="footer_description">
        Stay up to date on our portfolio nonprofits and the future of philanthropy!
      </p> */}
      {/* <form action="/action_page.php" class="email_form">
          <input type="email" id="email" name="email" placeholder="Email"/>
          <button id="email_submit" type="submit" onSubmit={onEmailSubmit}> Sign Up </button>
      </form> */}
      <ul className="icons">
        <li>
          <a href="https://www.instagram.com/ppe4.all/">
            <img src={ig_logo}/>
          </a>
        </li>
        <li>
        <li>
          <a
            href="https://twitter.com/PPE4ALL">
            <img src={twit_logo}/>
          </a>
        </li>
          <a href="https://www.linkedin.com/company/ppe4all/">
            <img src={li_logo}/>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/PPE4ALL">
            <img src={fb_logo}/>
          </a>
        </li>
      </ul>
    </section>
    
    {/* <p className="copyright">
      &copy; PWR4 Inc.
    </p> */}
  </footer>
)

export default Footer
